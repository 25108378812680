/* eslint-disable */
import { BiShoppingBag, BiPencil } from "react-icons/bi";
import { BsCodeSlash, BsClipboardData } from "react-icons/bs";
import { AiOutlineMail, AiFillAudio } from "react-icons/ai";
import { FaInternetExplorer,  FaChalkboardTeacher, FaCameraRetro, FaPinterest, FaVideo, FaTabletAlt, FaRegNewspaper } from "react-icons/fa";

 

export const servicesData = [
    // {
    //     id: 1,
    //     title: 'Marketing',
    //     icon: <BiShoppingBag/>
    // },
//    {
//        id: 2,
//        title: 'Content Writing',
//        icon: <BiPencil />
//    },
//    {
//        id: 3,
//        title: 'Email Marketing',
//        icon: <AiOutlineMail />
//    },
    // {
    //     id: 4,
    //     title: 'Internet Research',
    //     icon: <FaInternetExplorer />
    // },
     {
         id: 5,
         title: 'Full Stack Development',
         icon: <FaChalkboardTeacher />
     },

    {
        id: 6,
        title: 'Web Design',
        icon: <BsCodeSlash />
    },
//    {
//        id: 7,
//        title: 'Photography',
//        icon: <FaCameraRetro />
//    },
    // {
    //     id: 8,
    //     title: 'Pinterest Virtual Assistant',
    //     icon: <FaPinterest />
    // },
//    {
//        id: 9,
//        title: 'Video Editing',
//        icon: <FaVideo />
//    },
     {
         id: 10,
         title: 'Blockchain Engineering',
         icon: <BsClipboardData />
     },
//    {
//        id: 11,
//        title: 'Ebook Writing',
//        icon: <FaTabletAlt />
//    },
    // {
    //     id: 12,
    //     title: 'Audio Transcription',
    //     icon: <AiFillAudio />
    // },
    {
        id: 13,
        title: 'Freelance software development',
        icon: <FaRegNewspaper />
    },

]

// Uncomment your required service.
// Couldn't find the required services? Raise an issue on github at https://github.com/hhhrrrttt222111/developer-portfolio/issues/new
// You can also add on your own 😉.