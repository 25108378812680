import resume from '../assets/pdf/resume.pdf'
import profileImg from '../assets/png/headshot.png'

export const headerData = {
    name: 'Gage Wassweiler',
    title: "Software Developer",
    desciption:"I am a full stack software developer with interests in webApps, web design, and blockchain engineering. My goals are to continuously improve my development skills and explore new and innovative technologies.",
    image: profileImg,
    resumePdf: resume
}
