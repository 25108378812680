export const educationData = [
    {
        id: 1,
        institution: 'University of Alabama',
        course: 'Bachelor of Computer Science',
        startYear: '2016',
        endYear: '2020'
    },
    {
        id: 2,
        institution: 'University of Alabama',
        course: 'Minor in Mathematics',
        startYear: '2016',
        endYear: '2020'
    },
    {
        id: 2,
        institution: 'University of Alabama',
        course: 'Minor in Creative Media',
        startYear: '2016',
        endYear: '2020'
    }
]