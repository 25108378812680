export const experienceData = [
    {
        id: 1,
        company: 'Southwest Research Institute Space Science Division',
        jobtitle: 'Research Computer Scientist',
        startYear: '2023',
        endYear: 'Present'
    },
    {
        id: 2,
        company: 'Southwest Research Institute Space Science Division' ,
        jobtitle: 'Computer Scientist',
        startYear: '2020',
        endYear: '2023'
    },
    {
        id: 3,
        company: 'Freelance Developer',
        jobtitle: 'Full stack developer with a focus in blockchain engineering',
        startYear: '2021',
        endYear: 'Present'
    },
    {
        id: 4,
        company: 'Gestamp Engineering',
        jobtitle: 'Electrical Engineering Intern',
        startYear: '2016',
        endYear: '2017'
    },
]